import { FieldsNameUsingKey } from "../Constant/StaticData";
import CustomAlert from "./CustomAlert";
const baseUrl = window.location.origin;

export const handleLogout = () => {
  localStorage.clear();
  // window.location.reload();
  window.location.replace(window.location.origin);
};

export const convertDateFormat = (data) => {
  const date = new Date(data);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`; // Format date as dd/mm/yyyy
};

export const formatDataForGraph = (Gdata) => {
  let data = [];
  for (let i = 0; i < 10; i++) {
    data?.push({
      count: Gdata?.[i]?.count,
      data: Gdata?.[i]?.data,
    });
  }
  return data;
};

export const formatDownlableData = (data) => {
  return data.map((obj) => {
    let { createdAt, updatedAt, __v, _id, ...cleanedObj } = obj;
    cleanedObj.Date = convertDateFormat(cleanedObj?.Date);
    return cleanedObj;
  });
};

//Preparing the data according to search import/export API
export const handleSerchDataFormat = (data, page, isForDownload) => {
  let formatedData = {
    search_text: {},
    duration: {},
    pagination: {},
    filters: {},
  };

  if (data) {
    if (data?.chapter) {
      formatedData["chapter_code"] = String(data?.chapter);
    }

    if (data?.selectedFieldType === "product_name" || data?.selectedFieldType === "hs_code") {
      formatedData.search_text[data?.selectedFieldType] = data?.selectedField;
    }

    // formatedData.search_text["hs_code"] = ["30"];

    if (data?.hsCode) {
      formatedData.search_text["hs_code"] = data.hsCode;
    }

    // if (data?.productName) {
    //   formatedData.search_text["product_name"] = data.productName;
    // }

    if (data?.selectedFieldType === "importer_name" && data?.s_importer_name) {
      let fData = data?.selectedField.concat(data?.s_importer_name);
      formatedData.filters["importer_name"] = fData;
    } else if (data?.selectedFieldType?.length && data?.selectedFieldType === "importer_name") {
      formatedData.filters["importer_name"] = data?.selectedField;
    } else if (data?.s_importer_name?.length && data?.s_importer_name) {
      formatedData.filters["importer_name"] = data?.s_importer_name;
    }

    if (data?.selectedFieldType === "exporter_name" && data?.s_exporter_name) {
      let fData = data?.selectedField.concat(data?.s_exporter_name);
      formatedData.filters["exporter_name"] = fData;
    } else if (data?.selectedFieldType?.length && data?.selectedFieldType === "exporter_name") {
      formatedData.filters["exporter_name"] = data?.selectedField;
    } else if (data?.s_exporter_name?.length && data?.s_exporter_name) {
      formatedData.filters["exporter_name"] = data?.s_exporter_name;
    }

    if (data?.selectedFieldType === "country" && data?.s_country) {
      let fData = data?.selectedField.concat(data?.s_country);
      formatedData.filters["country"] = fData;
    } else if (data?.selectedFieldType?.length && data?.selectedFieldType === "country") {
      formatedData.filters["country"] = data?.selectedField;
    } else if (data?.s_country?.length && data?.s_country) {
      formatedData.filters["country"] = data?.s_country;
    }

    if (data?.country_of_destination?.length > 0) {
      formatedData.filters["country_of_destination"] = data.country_of_destination;
    }

    if (data?.unit?.length > 0) {
      formatedData.filters["unit"] = data.unit;
    }

    if (data?.mode_of_shipment?.length > 0) {
      formatedData.filters["mode_of_shipment"] = data.mode_of_shipment;
    }

    if (data?.month?.length > 0) {
      formatedData.filters["month"] = data.month;
    }

    if (data?.selectedFieldType === "port_code" && data?.port_of_discharge) {
      let fData = data?.selectedField.concat(data?.port_of_discharge);
      formatedData.filters["port_code"] = fData;
    } else if (data?.selectedFieldType?.length && data?.selectedFieldType === "port_code") {
      formatedData.filters["port_code"] = data?.selectedField;
    } else if (data?.port_of_discharge?.length && data?.port_of_discharge) {
      formatedData.filters["port_code"] = data?.port_of_discharge;
    }

    if (data?.port_of_loading?.length > 0) {
      formatedData.filters["port_of_loading"] = data.port_of_loading;
    }

    // if (data?.portNo) {
    //   formatedData.filters["port_code"] = data.portNo;
    // }
    // if (data?.unit) {
    //   formatedData.filters["unit"] = data.unit;
    // }

    if (data?.start_date) {
      formatedData.duration["start_date"] = `${data.start_date}T00:00:00`;
    }
    if (data?.end_date) {
      formatedData.duration["end_date"] = `${data.end_date}T23:59:59`;
    }

    if (page) {
      formatedData.pagination["page_index"] = isForDownload ? "1" : String(page);
      formatedData.pagination["page_size"] = isForDownload ? "20000" : "25";
    }
    if (isForDownload) {
      formatedData["download_sub"] = true;
    }

    if (data?.sort) {
      formatedData.sort = {
        sort_order: data?.sort?.sort_order,
        sort_field: data?.sort?.sort_field,
      };
    }

    // For link
    if (data?.importer_name_link?.length > 0) {
      formatedData.filters["importer_name"] = data.importer_name_link;
    }
    if (data?.exporter_name_link?.length > 0) {
      formatedData.filters["exporter_name"] = data.exporter_name_link;
    }
    if (data?.country_link?.length > 0) {
      formatedData.filters["country"] = data.country_link;
    }
    if (data?.port_of_loading_link?.length > 0) {
      formatedData.filters["port_code"] = data.port_of_loading_link;
    }
    if (data?.port_of_discharge_link?.length > 0) {
      formatedData.filters["port_of_discharge"] = data.port_of_discharge_link;
    }
    if (data?.month_link?.length > 0) {
      formatedData.filters["month"] = data.month_link;
    }
    //================
  }
  return formatedData;
};

//Validate trade analysis form
export const ValidateTradeAnalysisForm = (formData) => {
  // let hsCodeLength = [2, 4, 6, 8];
  let finalReport = {
    status: true,
    errorList: {},
  };
  // if (!formData?.chapter) {
  //   finalReport.status = false;
  //   finalReport.errorList["chapter"] = { status: true, message: "Chapter is required!" };
  // }
  // if (!formData?.dataType) {
  //   finalReport.status = false;
  //   finalReport.errorList["dataType"] = { status: true, message: "Type is required!" };
  // }
  if (!(formData?.selectedField?.length > 0)) {
    finalReport.status = false;
    finalReport.errorList["selectedField"] = { status: true, message: `${FieldsNameUsingKey[formData?.selectedFieldType]} is required!` };
  }
  if (!formData?.start_date) {
    finalReport.status = false;
    finalReport.errorList["start_date"] = { status: true, message: "Date Range is required!" };
  } else if (formData?.start_date > formData?.end_date) {
    finalReport.status = false;
    finalReport.errorList["start_date"] = { status: true, message: "start date should be less than Till date!" };
  }

  if (!formData?.end_date) {
    finalReport.status = false;
    finalReport.errorList["end_date"] = { status: true, message: "Date Range is required!" };
  }
  return finalReport;
};

export const validatedateformat = (data) => {
  if (data?.start_date < data?.end_date) {
    return false;
  } else {
    return true;
  }
};

export const ValidateTradeAnalysisFormNew = (name, value, chapter) => {
  let finalReport = {
    status: true,
    errorList: { selectedField: { status: false, message: "" } },
  };

  let hsCodeLength = [4, 6, 8];
  if (name === "hs_code") {
    if (!Number(value)) {
      finalReport.status = false;
      finalReport.errorList["selectedField"] = { status: true, message: `HSN Code should be number only` };
    } else {
      let valueSplit = value?.split("");
      let chapteSplit = chapter.toString()?.split("");

      if (!(valueSplit?.[0] == chapteSplit?.[0] && valueSplit?.[1] == chapteSplit?.[1])) {
        finalReport.status = false;
        finalReport.errorList["selectedField"] = { status: true, message: `HSN Code should be according to chapter you have selected` };
      } else if (!hsCodeLength.includes(value?.length)) {
        finalReport.status = false;
        finalReport.errorList["selectedField"] = { status: true, message: `HSN Code should be in 4,6, 8 digit format` };
      }
    }
  } else if (value?.length < 3) {
    finalReport.status = false;
    finalReport.errorList["selectedField"] = { status: true, message: `${FieldsNameUsingKey[name]} should be min 3 character` };
  }

  return finalReport;
};

export const GetPagination = (value) => {
  let data = (value / 25).toString().split(".");
  if (Number(data?.[1]) > 0) {
    return Number(data?.[0]) + 1;
  } else {
    return Number(data?.[0]);
  }
};

export const subscriptionModal = () => {
  CustomAlert("Subscription Not Found!", "You don't have subscription to view all the data. Please connect support", "warning", 3000);
};

// Create url for full table data while on click of the link/data.
export const handleOnClickUrlForFullTable = (keyVal, val, filterFor) => {
  if (filterFor !== "usa") {
    window.open(`${baseUrl}/#/trade-analysis-table?country=india&isFind=true&keyVal=${keyVal}&val=${val}`, "_blank");
  }
};

// Covert Currency in short form
export const formatNumberSort = (amount) => {
  if (amount >= 1_000_000_000) {
    return (amount / 1_000_000_000).toFixed(2) + " B";
  } else if (amount >= 1_000_000) {
    return (amount / 1_000_000).toFixed(2) + " M";
  } else if (amount >= 1_000) {
    return (amount / 1_000).toFixed(2) + " K";
  } else if (amount >= 100) {
    return (amount / 100).toFixed(2) + " hundred";
  } else {
    return String(amount);
  }
};

//Sort the array
export const sortArrayByKey = (array, key) => {
  return array.sort((a, b) => b[key] - a[key]);
};
