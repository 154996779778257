import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Status from "../Status/Status";
import imgPath from "../../assets/images/dataNotFound.png";
import { NO_DATA_FOUND, URL } from "../../Constant/StaticData";
import { importTableConstant, exportTableConstant, usa_importTableConstant } from "../../Pages/ImportExport/TableConstant";
import { convertDateFormat, subscriptionModal } from "../../Utils/commonFunction";
import { IconButton } from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { useNavigate } from "react-router-dom";
import PromotionalPopup from "../PromotionalPopup";
import CustomizedDialogs from "../popup/Popup";
import PromotionsBanner from "../PromotionsBanner";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    // whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomizedTables = (props) => {
  const navigate = useNavigate();
  const [isShowDemo, setIsShowDemo] = useState(false);
  const { tableData, filterType, isSubscription, triggerOrder } = props;
  const [tableHeaderKey, setTableHeaderKey] = useState([]);
  const [tableBodyKey, setTableBodyKey] = useState([]);
  const [quantityOrder, setQuantityOrder] = useState(false);
  const [totalValueUSDOrder, setTotalValueUSDOrder] = useState(false);
  // const fullTableLink = props?.fullTableLink || "trade-analysis-table?country=india";
  const url = window.location.href;
  let query = url.split("?country=")?.[1];
  const baseUrl = window.location.origin;

  const handleAscDsc = (orderFor) => {
    if (orderFor === "Quantity") {
      let finalValue = quantityOrder === "asc" ? "desc" : "asc";
      setQuantityOrder(finalValue);
      triggerOrder(orderFor, finalValue);
    } else if (orderFor === "Total_Value_USD") {
      let finalValue = totalValueUSDOrder === "asc" ? "desc" : "asc";
      setTotalValueUSDOrder(finalValue);
      triggerOrder(orderFor, finalValue);
    }
  };

  useEffect(() => {
    if (query === "usa") {
      if (filterType === "import") {
        if (isSubscription) {
          setTableHeaderKey(usa_importTableConstant?.headerKey);
          setTableBodyKey(usa_importTableConstant?.bodyKey);
        } else {
          setTableHeaderKey(usa_importTableConstant?.demoHeaderKey);
          setTableBodyKey(usa_importTableConstant?.demoBodyKey);
        }
      } else {
        if (isSubscription) {
          setTableHeaderKey(exportTableConstant?.headerKey);
          setTableBodyKey(exportTableConstant?.bodyKey);
        } else {
          setTableHeaderKey(exportTableConstant?.demoHeaderKey);
          setTableBodyKey(exportTableConstant?.demoBodyKey);
        }
      }
    } else {
      if (filterType === "import") {
        if (isSubscription) {
          setTableHeaderKey(importTableConstant?.headerKey);
          setTableBodyKey(importTableConstant?.bodyKey);
        } else {
          setTableHeaderKey(importTableConstant?.demoHeaderKey);
          setTableBodyKey(importTableConstant?.demoBodyKey);
        }
      } else {
        if (isSubscription) {
          setTableHeaderKey(exportTableConstant?.headerKey);
          setTableBodyKey(exportTableConstant?.bodyKey);
        } else {
          setTableHeaderKey(exportTableConstant?.demoHeaderKey);
          setTableBodyKey(exportTableConstant?.demoBodyKey);
        }
      }
    }
  }, [tableData]);

  return (
    <div className="customizedTable">
      {tableData?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table" padding="6px" style={{ fontSize: "12px!important" }}>
            <TableHead>
              <TableRow>
                {tableHeaderKey?.map((item) => (
                  <StyledTableCell align="left">
                    {item}
                    {isSubscription &&
                      item === "Quantity" &&
                      (quantityOrder === "desc" ? (
                        <KeyboardDoubleArrowDownIcon onClick={() => handleAscDsc("Quantity")} style={{ cursor: "pointer", color: "#FF9D23" }} />
                      ) : (
                        <KeyboardDoubleArrowUpIcon onClick={() => handleAscDsc("Quantity")} style={quantityOrder === "asc" ? { cursor: "pointer", color: "#FF9D23" } : { cursor: "pointer", color: "#d2d2d2" }} />
                      ))}

                    {isSubscription &&
                      item === "Total Value USD" &&
                      (totalValueUSDOrder === "desc" ? (
                        <KeyboardDoubleArrowDownIcon onClick={() => handleAscDsc("Total_Value_USD")} style={{ cursor: "pointer", color: "#FF9D23" }} />
                      ) : (
                        <KeyboardDoubleArrowUpIcon onClick={() => handleAscDsc("Total_Value_USD")} style={totalValueUSDOrder === "asc" ? { cursor: "pointer", color: "#FF9D23" } : { cursor: "pointer", color: "#d2d2d2" }} />
                      ))}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            {query === "usa" ? (
              // Table for USA Data
              <TableBody>
                {isSubscription
                  ? tableData?.map((item) => (
                      <StyledTableRow key={item?.id}>
                        {tableBodyKey?.map((data, index) => (
                          <StyledTableCell
                            align="left"
                            style={
                              data === "Item_Description" || data === "Importer_Address" || data === "Exporter_Address" || data === "Supplier_Address" || data === "Buyer_Address"
                                ? { minWidth: "25%", maxWidth: "350px", padding: "6px 16px" }
                                : { padding: "6px 16px" }
                            }
                            key={index}
                          >
                            <div className="scroll" style={data === "Date" ? { width: "100px" } : data === "Item_Description" ? { minWidth: "25%", maxWidth: "350px" } : {}}>
                              {data === "Date" ? convertDateFormat(item[data]) : item[data]}
                            </div>
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))
                  : tableData?.map((item) => (
                      <StyledTableRow key={item?.id}>
                        {tableBodyKey?.map((data, index) => (
                          <StyledTableCell align="left" key={index} style={data === "Item_Description" ? { minWidth: "25%", maxWidth: "350px", padding: "6px 16px" } : { padding: "6px 18px" }}>
                            <div className="scroll" style={data === "Date" ? { width: "100px" } : data === "Item_Description" ? { minWidth: "25%", maxWidth: "350px" } : {}}>
                              {data === "Date" ? (
                                convertDateFormat(item[data])
                              ) : (data === "Importer_Name" || data === "Exporter_Name") && !props?.isLockHide ? (
                                <LockPersonIcon style={{ cursor: "pointer" }} color="info" onClick={() => subscriptionModal()} />
                              ) : (
                                item[data]
                              )}
                            </div>
                          </StyledTableCell>
                        ))}
                        <StyledTableCell align="left">
                          <IconButton color="primary" aria-label="add to shopping cart" onClick={() => window.open(`${baseUrl}/#/${props?.fullTableLink}`, "_blank")}>
                            <KeyboardDoubleArrowRightIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            ) : (
              // Table for Indian Data
              <TableBody>
                {isSubscription
                  ? tableData?.map((item) => (
                      <StyledTableRow key={item?.id}>
                        {tableBodyKey?.map((data, index) => (
                          <StyledTableCell
                            align="left"
                            style={
                              data === "Item_Description" || data === "Importer_Address" || data === "Exporter_Address" || data === "Supplier_Address" || data === "Buyer_Address" ? { minWidth: "300px", maxWidth: "300px" } : { whiteSpace: "nowrap" }
                            }
                            key={index}
                          >
                            {data === "Date" ? convertDateFormat(item[data]) : item[data]}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))
                  : tableData?.map((item) => (
                      <StyledTableRow key={item?.id}>
                        {tableBodyKey?.map((data, index) => (
                          <StyledTableCell align="left" key={index}>
                            {data === "Date" ? convertDateFormat(item[data]) : item[data]}
                          </StyledTableCell>
                        ))}
                        <StyledTableCell align="left">
                          <IconButton color="primary" aria-label="add to shopping cart" onClick={() => window.open(`${baseUrl}/#/${props?.fullTableLink}`, "_blank")}>
                            <KeyboardDoubleArrowRightIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Status imgPath={imgPath} heading={NO_DATA_FOUND?.heading} message={NO_DATA_FOUND?.message} />
      )}
      <CustomizedDialogs width="sm" isOpen={isShowDemo}>
        <PromotionsBanner
          heading="You don't have subscription!"
          text="Please connect with us on +91-9821342553 or info@zcelindia.com to get or renew your subscription. You can also click on the button to message our support and will call you shortly."
          onOkayClick={() => navigate("/contact-us")}
          onCancelClick={() => setIsShowDemo(false)}
        />
      </CustomizedDialogs>
    </div>
  );
};

export default CustomizedTables;
