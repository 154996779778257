import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
// import CardMedia from "@mui/material/CardMedia";

export default function ActionCard(props) {
  return (
    <Card sx={{ maxWidth: 345, backgroundColor: "#cce7ff" }}>
      <CardActionArea>
        {/* <CardMedia component="img" height="100" image={banner_img} alt="green iguana" /> */}
        {/* <Avatar sx={{ bgcolor: "transparent", float: "right", margin: "15px", color: "#fff", fontSize: "80px" }}>{props?.icon}</Avatar> */}

        <img src={props?.img} alt="icon" style={{ width: "100px", float: "right", padding: "20px" }} />

        <CardContent>
          <Typography variant="body2" color="#337ab7">
            {props?.title}
          </Typography>
          <Typography gutterBottom variant="h5" component="div" color="#1c70b7">
            {props?.count}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
