import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "./loaderstyle.css";

const Loader = (props) => {
  return (
    props?.open && (
      <div>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} style={props?.customStyle}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    )
  );
};

export default Loader;
