import React from "react";
import Plan from "../../components/Plan/Plan";
import { Footer, About, Header } from "../../components";
import { Testimonial, ControlledCarousel } from "../../components";

const Home = () => {
  return (
    <div className="home">
      <Header />
      <ControlledCarousel />
      <About />
      <Plan />
      <Testimonial />
      <Footer />
    </div>
  );
};
export default Home;
