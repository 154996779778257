import { Grid } from "@mui/material";
import React from "react";
import ActionCard from "../ActionCard";

import growthImg from "../../assets/images/icons/growth.png";
import country from "../../assets/images/icons/country.png";
import importer from "../../assets/images/icons/import.png";
import exporter from "../../assets/images/icons/export.png";
import totalValueInUSD_img from "../../assets/images/icons/currency.png";
import { formatNumberSort } from "../../Utils/commonFunction";

const DashboardReportBox = (props) => {
  let { Shipments, Country, Exporter, Importer, Total_Value_USD } = props?.data;
  // let { totalShipment, totalCountry, totalExporter, totalImporter, totalValueInUSD } = props?.displayList;
  let noOfBoard = 100 / props?.noOfBoard;

  return (
    <>
      {Shipments && (
        <Grid container style={{ padding: "16px" }}>
          {props?.displayList?.totalShipment && (
            <Grid style={{ width: `${noOfBoard}%`, padding: "10px" }}>
              <ActionCard color="#337ab7" title="Total Shipment" count={Shipments || 0} img={growthImg} />
            </Grid>
          )}
          {props?.displayList?.totalCountry && (
            <Grid style={{ width: `${noOfBoard}%`, padding: "10px" }}>
              <ActionCard color="#d32f2f" title="Total Country" count={Country || 0} img={country} />
            </Grid>
          )}
          {props?.displayList?.totalExporter && (
            <Grid style={{ width: `${noOfBoard}%`, padding: "10px" }}>
              <ActionCard color="#006c04" title="Total Exporter" count={Exporter || 0} img={exporter} />
            </Grid>
          )}
          {props?.displayList?.totalImporter && (
            <Grid style={{ width: `${noOfBoard}%`, padding: "10px" }}>
              <ActionCard color="#ffcb00" title="Total Importer" count={Importer || 0} img={importer} />
            </Grid>
          )}
          {props?.displayList?.totalValueInUSD && (
            <Grid style={{ width: `${noOfBoard}%`, padding: "10px" }}>
              <ActionCard color="#ffcb00" title="Total Value USD" count={Total_Value_USD ? formatNumberSort(Total_Value_USD?.toFixed(2)) : 0} img={totalValueInUSD_img} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default DashboardReportBox;
