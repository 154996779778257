// import React from "react";
// import { useTheme } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// // import SwipeableViews from "react-swipeable-views";
// // import { autoPlay } from "react-swipeable-views-utils";
// import { Container } from "@mui/system";
// import { customStyle } from "./style";
// import { Grid, Rating } from "@mui/material";
// import SubHeading from "../SubHeading/SubHeading";
// import CustomParaGraph from "../CustomParaGraph";

// import ProfileImg1 from "../../assets/images/testimonial/testimonial1.jpg";
// import ProfileImg2 from "../../assets/images/testimonial/testimonial2.jpg";
// import ProfileImg3 from "../../assets/images/testimonial/testimonial3.jpg";

// // const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// const Testimonial = () => {
//   const theme = useTheme();
//   const [activeStep, setActiveStep] = React.useState(0);

//   const handleStepChange = (steps) => {
//     setActiveStep(steps);
//   };

//   return (
//     <div style={customStyle.mainContainer}>
//       <Container maxWidth="lg">
//         <Grid container spacing={2}>
//           <div>
//             <SubHeading
//               customStyle={{ color: "#337ab7!important", textAlign: "center" }}
//               text="Our Testimonial"
//             />
//             <CustomParaGraph
//               variant="body1"
//               customStyle={{ fontSize: "12px", textAlign: "center" }}
//               text="BizDiving is an online platform created by Zcel India Llp, where one can search, create and accelerate plans and procedures based on
//                 import and export for their business growth. This portal is highly dedicated for the Lifescience Industries."
//             />
//           </div>

//           <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
//             {/* <AutoPlaySwipeableViews
//               axis={theme.direction === "rtl" ? "x-reverse" : "x"}
//               index={activeStep}
//               onChangeIndex={handleStepChange}
//               enableMouseEvents
//             > */}
//             {images.map((steps, index) => (
//               <div key={steps.label}>
//                 {Math.abs(activeStep - index) <= 2 ? (
//                   <div style={customStyle.testimonial}>
//                     <Container maxWidth="lg">
//                       <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
//                         <div style={customStyle.profilePic}>
//                           <img
//                             src={steps.imgPath}
//                             alt="preson"
//                             className="testimonial__slide__card__profileImg"
//                           />
//                         </div>
//                         <Paper
//                           square
//                           elevation={1}
//                           sx={{
//                             borderRadius: 2,
//                             display: "flex",
//                             alignItems: "center",
//                             height: 150,
//                             pl: 2,
//                             bgcolor: "aliceblue",
//                             padding: "20px",
//                           }}
//                         >
//                           <div style={customStyle.data}>
//                             <Rating name="read-only" value={5} readOnly />
//                             <Typography
//                               variant="h6"
//                               gutterBottom
//                               component="div"
//                               style={{
//                                 fontWeight: "900",
//                                 textAlign: "center",
//                                 color: "#337ab7",
//                               }}
//                             >
//                               {steps.label}
//                             </Typography>

//                             <Typography
//                               variant="body1"
//                               style={{ width: "100%", textAlign: "center" }}
//                               gutterBottom
//                             >
//                               {steps.description}
//                             </Typography>
//                           </div>
//                         </Paper>
//                       </Box>
//                     </Container>
//                   </div>
//                 ) : null}
//               </div>
//             ))}
//             {/* </AutoPlaySwipeableViews> */}
//           </Box>
//         </Grid>
//       </Container>
//     </div>
//   );
// };

// export default Testimonial;

// const images = [
//   {
//     imgPath: ProfileImg1,
//     label: "Andrew",
//     description: `BizDiving has transformed our approach to international trade. The detailed market analysis and competitor insights have been invaluable for our strategic planning. Our business has seen significant growth since we started using this platform.`,
//   },
//   // {
//   //   imgPath: ProfileImg2,
//   //   label: "Olivia Rudd",
//   //   description: `The comprehensive database and user-friendly interface of BizDiving have made it so much easier to identify key players and trends in the Lifescience industry. It’s an essential tool for anyone looking to expand their business globally.`,
//   // },
//   // {
//   //   imgPath: ProfileImg3,
//   //   label: "Keshav Sharma",
//   //   description: `As a small manufacturer, BizDiving has given us the competitive edge we needed. The platform’s in-depth product information and market intelligence have helped us make informed decisions and connect with the right partners worldwide.`,
//   // },
// ];

import React from "react";
import Carousel from "react-bootstrap/Carousel";
import sliderImage1 from "../../assets/images/Slider/1.png";
import sliderImage2 from "../../assets/images/Slider/2.png";
import sliderImage3 from "../../assets/images/Slider/3.png";

import ProfileImg1 from "../../assets/images/testimonial/testimonial1.jpg";
import ProfileImg2 from "../../assets/images/testimonial/testimonial2.jpg";
import ProfileImg3 from "../../assets/images/testimonial/testimonial3.jpg";

import "bootstrap/dist/css/bootstrap.min.css";
import CustomParaGraph from "../CustomParaGraph";
import SubHeading from "../SubHeading";
import { Container, Grid, Rating } from "@mui/material";

function Testimonial() {
  return (
    <div className="Carousel_maindiv">
      <div>
        <SubHeading customStyle={{ color: "#337ab7!important", textAlign: "center" }} text="Our Testimonial" />
        <CustomParaGraph
          variant="body1"
          customStyle={{ fontSize: "12px", textAlign: "center" }}
          text="BizDiving is an online platform created by Zcel India Llp, where one can search, create and accelerate plans and procedures based on
                import and export for their business growth. This portal is highly dedicated for the Lifescience Industries."
        />
      </div>
      <Carousel data-bs-theme="dark" interval="2000" indicators={false} controls={false}>
        <Carousel.Item>
          <div className="w-100 carousel_div">
            <img className="d-block carousel_profileimg" src={ProfileImg1} alt="Second slide" />
            {/* <Carousel.Caption> */}
            <h5 style={{ color: "#337ab7", fontWeight: "600" }}>Andrew</h5>
            <Rating name="read-only" value={5} readOnly />
            <CustomParaGraph
              variant="body1"
              customStyle={{ fontSize: "12px", textAlign: "center" }}
              text="BizDiving has transformed our approach to international trade. The detailed market analysis and competitor insights have been invaluable for our strategic planning. Our business has seen significant growth since we started using this
              platform."
            />
            {/* </Carousel.Caption> */}
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="w-100 carousel_div">
            <img className="d-block carousel_profileimg" src={ProfileImg2} alt="Second slide" />
            {/* <Carousel.Caption> */}
            <h5 style={{ color: "#337ab7", fontWeight: "600" }}>Kelly</h5>
            <Rating name="read-only" value={5} readOnly />
            <CustomParaGraph
              variant="body1"
              customStyle={{ fontSize: "12px", textAlign: "center" }}
              text="The comprehensive database and user-friendly interface of BizDiving have made it so much easier to identify key players and trends in the Lifescience industry. It’s an essential tool for anyone looking to expand their business globally."
            />
            {/* </Carousel.Caption> */}
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="w-100 carousel_div">
            <img className="d-block carousel_profileimg" src={ProfileImg3} alt="Second slide" />
            {/* <Carousel.Caption> */}
            <h5 style={{ color: "#337ab7", fontWeight: "600" }}>Madhukeshav</h5>
            <Rating name="read-only" value={5} readOnly />
            <CustomParaGraph
              variant="body1"
              customStyle={{ fontSize: "12px", textAlign: "center" }}
              text="As a small manufacturer, BizDiving has given us the competitive edge we needed. The platform’s in-depth product information and market intelligence have helped us make informed decisions and connect with the right partners worldwide."
            />
            {/* </Carousel.Caption> */}
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Testimonial;
