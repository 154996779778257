import React, { useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { Button, Stack, Typography } from "@mui/material";

import { Footer, Header, PageLoader, Promotions, Status } from "../../../components";
import ApiRequest from "../../../utility/APIRequest";
import API_endpoint from "../../../utility/APIEndPoints";
import { CustomAlert } from "../../../Utils";
import BarChart from "../../../components/Barchart";
import { formatDataForGraph, handleOnClickUrlForFullTable, handleSerchDataFormat, ValidateTradeAnalysisForm } from "../../../Utils/commonFunction";
import CustomList from "../../../components/CustomList/CustomList";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import ExcelImg from "../../../assets/images/icons/excelImg.png";
import PdfImg from "../../../assets/images/icons/pdf.png";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";

const MonthWiseAnalysis = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [analysisData, setAnalysisData] = useState();
  const isMounted = useRef(true);

  const getFilterData = async (formData) => {
    if (formData) {
      let APIData = await handleSerchDataFormat(formData, "1");

      ApiRequest.request(`/analytics/${formData?.dataType}/month-analysis`, "POST", APIData).then((response) => {
        if (response?.status) {
          setAnalysisData(response?.result);
          setIsLoading(false);
        } else {
          setAnalysisData(null);
          setIsLoading(false);
        }
      });
    } else {
      CustomAlert("invalid Link", "Your link is expired! Please try again", "error", 50000000);
      setIsLoading(false);
    }
  };

  // download the excel of data
  const downloadExcel = () => {
    let data = analysisData;
    const hsn_code = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, hsn_code, "Country");

    XLSX.writeFile(workbook, "hsn_code_wise_analysis.xlsx");
    // setIsDownloading(false);
    CustomAlert("Thankyou for downloading!", "Your Data is downloaded sucessfully.", "success", 3000);
  };

  const generatePDF = () => {
    const content = document.getElementById("analysis");

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // Width of A4 page in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("country_wise_analysis.pdf");
    });
  };

  useEffect(() => {
    if (isMounted?.current) {
      let localStorageData = localStorage.getItem("india_filter");
      let data = JSON.parse(localStorageData);
      if (data) {
        getFilterData(data);
      } else {
        setIsLoading(false);
      }
    }
    return () => (isMounted.current = false);
  }, []);

  return (
    <div>
      <Header />

      <Container maxWidth="xl" className="import_export_filter">
        {isLoading ? (
          <>
            <PageLoader open={isLoading} />
            <div style={{ height: "70vh" }}></div>
          </>
        ) : analysisData ? (
          <Grid container spacing={2} className="import_export_filter__graphChart">
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                {/* <img src={PdfImg} alt="Download" style={{ width: "25px", cursor: "pointer" }} onClick={() => generatePDF()} /> */}
                <img src={ExcelImg} alt="Download" style={{ width: "25px", cursor: "pointer" }} onClick={() => downloadExcel()} />
              </Stack>
            </Grid>
            {/* <Grid item xs={6}>
              <Card>
                <div id="analysis">
                  <BarChart data={formatDataForGraph(analysisData?.countries)} />
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      backgroundColor: "#4a4a4a",
                      color: "#fff",
                    }}
                  >
                    Top 10 countries by value in USD
                  </Typography>
                </div>
              </Card>
            </Grid> */}

            <Grid item xs={6}>
              <CustomList leftTitle="Months" rightTitle="No of Shipment" value="totalCount" keyName="month" data={analysisData} onClickEvent={(data) => handleOnClickUrlForFullTable("month_link", data)} />
            </Grid>
            <Grid item xs={6}>
              <CustomList leftTitle="Months" rightTitle="Total Value in USD" value="totalValue" keyName="month" data={analysisData} onClickEvent={() => null} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Promotions heading="You don't have subscription!" message="You don't have subscription for see full data. Please connect support to get the subscription or request for demo." />
          </>
        )}
      </Container>

      <Footer />
    </div>
  );
};
export default MonthWiseAnalysis;
