export const URL = {
  BASE_URL: "https://bizdiving.com/#/",
};
export const CONTACT_DETAILS = {
  SALES_EMAIL: "sales@zcelindia.com",
  SUPPORT_EMAIL: "info@zcelindia.com",
  SALES_MOBILE: "+91-9821342553",
  SUPPORT_MOBILE: "+91-8604507250",
};
export const Message = {
  SOMTHING_WENT_WRONG: "Somthing went wrong! Please try again",

  LOGIN_FAILED: "Somthing went wrong! Please try again",

  //Filter Form Validation
  HSH_CODE_REQUIRED: "HSH code is required!",
  COMPANY_NAME_REQUIRED: "Company Name is required!",
  PRODUCT_NAME_REQUIRED: "Product name is required!",
  DATA_TYPE_REQUIRED: "Data type is required!",
  COUNTRY_NAME_REQUIRED: "Country Name is required!",
  BUYERSUPPLIER_REQUIRED: "Buyer/Supplier Name is required!",
  PORT_REQUIRED: "Port number is required!",
  UNIT_REQUIRED: "Unit is required!",
  FROMDATE_REQUIRED: "From date is required!",
  TODATE_REQUIRED: "To date is required!",

  //Profile update validation
  FIRSTNAME_REQUIRED: "Please fill your first",
  LASTNAME_REQUIRED: "Please fill your last name",
  WORK_EMAIL_PATTERN: "Please fill your work email currectly",
  MOBILE_PATTERN: "Please fill you contact number currectly",
  COMPANY_ADDRESS_REQUIRED: "Please fill your company address",
  COMPANY_TYPE_REQUIRED: "Please select your company type",
};

export const NO_DATA_FOUND = {
  heading: "No records found!",
  message: "No records found so please try with different data or contact with our support on +91-8604507250 or email at info@zcelindia.com",
};
export const BUYER_CONSTANT = {
  page_heading: "Explore Buyer Companies",
  Page_message:
    "Discover potential business partners effortlessly on our website! Seamlessly search for buyer companies and access comprehensive details using the company name or other relevant criteria. Empower your decision-making with in-depth insights, enhancing your networking and collaboration opportunities. Explore the future of B2B connections on our user-friendly platform, simplifying your quest for the right buyer companies. Unlock a world of possibilities and streamline your business interactions with our intuitive search functionality.",
};

export const SUPPLIER_CONSTANT = {
  page_heading: "Explore Supplier Companies",
  Page_message:
    "Discover potential business partners effortlessly on our website! Seamlessly search for Supplier companies and access comprehensive details using the company name or other relevant criteria. Empower your decision-making with in-depth insights, enhancing your networking and collaboration opportunities. Explore the future of B2B connections on our user-friendly platform, simplifying your quest for the right buyer companies. Unlock a world of possibilities and streamline your business interactions with our intuitive search functionality.",
};

export const GET_SUBSCRIPTION = {
  heading: "Get your Subscription now!",
  message:
    "Discover potential business partners effortlessly on our website! Seamlessly search for buyer companies and access comprehensive details using the company name or other relevant criteria. Empower your decision-making with in-depth insights, enhancing your networking and collaboration opportunities. Explore the future of B2B connections on our user-friendly platform, simplifying your quest for the right buyer companies. Unlock a world of possibilities and streamline your business interactions with our intuitive search functionality.",
};

export const RESET_PASSWORD = {
  password: "Please enter a valid pasword",
  confirm_password: "Please enter a valid confirm pasword",
  PASSWORD_NOT_MATCH: "Your password & Confirm password doesn't match.",
};

export const SOMETHING_WENT_WRONG_CONSTANT = {
  TITLE: "Oops! Something Went Wrong",
  MESSAGE: "We apologize, but it seems something went wrong on our end. If you are facing any issues, please try again. If the problem persists, feel free to reach out to our support team at:",
  ANOTHER_MESSAGE: "Thank you for your patience and understanding!",
};

export const PROFILE_CONSTANT = {
  PAGE_TITLE: "User Profile",
  PAGE_DISCRIPTION:
    "Welcome to your User Profile page! Here, you can view and update your personal information to ensure it is always current. Keep your details up-to-date to enhance your experience and receive the best service. You can update your name, email address, contact information, password, and more. Make sure to save any changes before leaving the page. Your privacy and security are our top priorities.",
};

export const chapterList = [
  { name: 13, value: 13 },
  { name: 21, value: 21 },
  { name: 25, value: 25 },
  { name: 27, value: 27 },
  { name: 28, value: 28 },
  { name: 29, value: 29 },
  { name: 30, value: 30 },
  { name: 31, value: 31 },
  { name: 32, value: 32 },
  { name: 33, value: 33 },
  { name: 34, value: 34 },
  { name: 38, value: 38 },
  { name: 39, value: 39 },
  { name: 40, value: 40 },
  { name: 90, value: 90 },
];

export const filterFields = [
  { name: "Product", value: "product_name" },
  { name: "Country", value: "country" },
  { name: "Importer", value: "importer_name" },
  { name: "Exporter", value: "exporter_name" },
  { name: "HSN code", value: "hs_code" },
  { name: "port code", value: "port_code" },
];

export const FieldsNameUsingKey = {
  product_name: "Product",
  country: "Country",
  buyer_name: "Buyer",
  supplier_name: "Supplier",
  hs_code: "HSN code",
  port_code: "port code",
};

const filterData = {
  search_text: {
    hs_code: "30",
    product_name: "carvedi",
  },
  duration: {
    start_date: "2024-08-01T00:00:00",
    end_date: "2024-08-06T23:59:59",
  },
  pagination: {
    page_index: "1",
    page_size: "25",
  },
  filters: {
    country: "INDIA",
    buyer_name: "keshav",
    supplier_name: "sharma",
    port_code: "122017",
    unit: "mt",
  },
};

export const multiSelectList = {
  country: ["India", "Pakistan", "Japan", "North Koria", "South Koria", "Itly", "Ameria", "Austriliya"],
  importer: ["Importer name 1", "Importer name 2", "Importer name 3", "Importer name 4", "Importer name 5", "Importer name 6", "Importer name 7", "Importer name 8"],
  exporter: ["Exporter name 1", "Exporter name 2", "Exporter name 3", "Exporter name 4", "Exporter name 5", "Exporter name 6", "Exporter name 7", "Exporter name 8"],
};

export const displayListForIndianData = {
  totalShipment: true,
  totalCountry: true,
  totalExporter: true,
  totalImporter: true,
  totalValueInUSD: true,
};

export const displayListForUSAData = {
  totalShipment: true,
  totalCountry: true,
  totalExporter: true,
  totalImporter: true,
  totalValueInUSD: true,
};
