import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Alert, AlertTitle } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function NotificationBadge() {
  const isDisplayed = useRef(true);
  const [isShow, setIsShow] = useState(true);
  useEffect(() => {
    if (isDisplayed?.current) {
      isDisplayed.current = false;
      toast.custom((t) => (
        <Alert
          // variant="outlined"
          style={{ marginBottom: "0px!important" }}
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={() => setIsShow(false)}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>December 2024</AlertTitle>
          Data uploaded!
        </Alert>
      ));
    }
  }, []);

  return (
    <>
      {isShow && (
        <Toaster
          position="top-right"
          reverseOrder={true}
          toastOptions={{
            duration: 100000000000,
          }}
        />
      )}
    </>
  );
}
